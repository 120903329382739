<template>
  <div class="container">
    <el-image
      class="banner"
      :src="require('@as/serve/tender/banner.png')"
    ></el-image>
    <div class="main">
      <div class="total_info">
        <el-image
          class="total_icon"
          :src="require('@as/serve/tender/total_icon.png')"
        ></el-image>
        <div class="item">
          <div class="label">累计发布：资格预审公关</div>
          <div class="value">60933</div>
        </div>
        <div class="item">
          <div class="label">招标公告</div>
          <div class="value">60933</div>
        </div>
        <div class="item">
          <div class="label">中标候选人公示</div>
          <div class="value">60933</div>
        </div>
        <div class="item">
          <div class="label">中标结果公示</div>
          <div class="value">60933</div>
        </div>
        <div class="item">
          <div class="label">更正公关公示</div>
          <div class="value">60933</div>
        </div>
        <div class="item">
          <div class="label">总</div>
          <div class="value">60933</div>
        </div>
      </div>
      <div class="content">
        <div class="left">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="招标公告" name="first">
              <div class="content_container">
                <div class="list">
                  <div
                    class="item"
                    v-for="(item, index) in list"
                    v-bind:key="item"
                    @click="toDetail()"
                  >
                    <div class="title">
                      <div>
                        兰州局集团公司兰州车辆段关于兰州客整所电梯维修项目招标公告
                      </div>
                      <div class="tag" :class="{ yellow: index == 0 }">
                        收费
                      </div>
                    </div>
                    <div class="info">
                      <div class="info_item">招标地区：河北省</div>
                      <div class="info_item">发布日期：2020-09-21</div>
                    </div>
                    <div
                      class="collect"
                      :class="{ active: item.active }"
                      @click.stop="item.active = !item.active"
                    >
                      <el-image
                        class="collect_icon"
                        :src="
                          item.active
                            ? require('@as/serve/tender/collect_icon_active.png')
                            : require('@as/serve/tender/collect_icon.png')
                        "
                      ></el-image>
                      <div>收藏</div>
                    </div>
                  </div>
                </div>
                <div class="pull_more">查看更多</div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="变更公告" name="second">
              <div class="content_container">变更公告</div></el-tab-pane
            >
            <el-tab-pane label="评标公示" name="third">
              <div class="content_container">评标公示</div></el-tab-pane
            >
            <el-tab-pane label="中标公告" name="fourth">
              <div class="content_container">中标公告</div></el-tab-pane
            >
          </el-tabs>
          <div class="float">
            <div class="item active">
              <div>时间</div>
              <i class="el-icon-arrow-down"></i>
            </div>
            <div class="item">
              <div>地区</div>
              <i class="el-icon-arrow-down"></i>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="section">
            <div class="color_title">
              <el-image
                class="title_icon"
                :src="require('@as/serve/tender/right_title.png')"
              ></el-image>
              <div class="title">
                <el-image
                  class="img"
                  :src="require('@as/reading/word.png')"
                ></el-image>
                <div>Say you, Say me</div>
              </div>
              <div class="right_text">共建共享</div>
            </div>
            <div class="section_content">
              <div class="item">
                <el-image
                  class="img"
                  :src="require('@as/reading/wenzhang.png')"
                ></el-image>
                <div class="name">创建招标</div>
              </div>
              <div class="item">
                <el-image
                  class="img"
                  :src="require('@as/reading/zhuanlan.png')"
                ></el-image>
                <div class="name">我的招标</div>
              </div>
            </div>
          </div>
          <div class="section">
            <div class="head">
              <div class="title">
                <el-image
                  class="title_icon"
                  :src="require('@as/serve/tender/head_icon.png')"
                ></el-image>
                <div>热门招标</div>
              </div>
              <div class="title_more"></div>
            </div>
            <div class="list">
              <div class="item" v-for="item in 5" v-bind:key="item">
                <div class="title">
                  中国进出口银行湖南省分行五凌电力孟加拉风电
                  法律服务项目-招标公告
                </div>
                <div class="subtitle">
                  <div>河北省-保定市</div>
                  <div>2020-10-21</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'first',
      activeIdx: 1,
      list: [
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
        { active: false },
      ],
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    },
    toDetail() {
      this.$router.push('tenderDetail')
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  background: #f5f7f8;
}
.banner {
  width: 100%;
  height: 280px;
}
.main {
  margin: 20px auto;
  width: 1200px;
  .total_info {
    padding: 13px 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ebf1f5;
    border: 1px solid #1c99b0;
    border-radius: 3px;
    .total_icon {
      flex-shrink: 0;
      width: 14px;
      height: 14px;
    }
    .item {
      margin-right: 60px;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      .label {
        color: #999999;
      }
      .value {
        margin-left: 5px;
        color: #1c99b0;
      }
    }
  }
  .content {
    margin-top: 20px;
    padding-bottom: 80px;
    display: flex;
    .left {
      margin-top: 9px;
      margin-right: 20px;
      position: relative;
      flex: 1;
      & /deep/ .el-tabs__item {
        font-size: 16px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
      }
      & /deep/ .el-tabs__item.is-active {
        color: #1c99b0;
      }
      & /deep/ .el-tabs__active-bar {
        background: #1c99b0;
      }
      .float {
        position: absolute;
        top: 5px;
        right: 20px;
        display: flex;
        .item {
          margin-left: 40px;
          display: flex;
          align-items: center;
          font-size: 13px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #595757;
          i {
            margin-left: 8px;
          }
          &.active {
            color: #1c9ab1;
          }
        }
      }
      .list {
        .item {
          position: relative;
          padding: 25px 0;
          border-bottom: 1px solid #dedede;
          cursor: pointer;
          .title {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #333333;
            .tag {
              margin-left: 4px;
              padding: 2px 0 2px 6px;
              width: 39px;
              height: 16px;
              line-height: 11px;
              text-align: center;
              font-size: 10px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #ffffff;
              background: center/100% 100% url('~@as/serve/tender/blue_tag.png')
                no-repeat;
              &.yellow {
                background: center/100% 100%
                  url('~@as/serve/tender/yellow_tag.png') no-repeat;
              }
            }
          }
          .info {
            margin-top: 20px;
            display: flex;
            align-items: center;
            .info_item {
              margin-right: 74px;
              font-size: 12px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #999999;
            }
          }
          .collect {
            position: absolute;
            right: 28px;
            top: 50%;
            transform: translateY(-50%);
            text-align: center;
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #999999;
            .collect_icon {
              margin-bottom: 8px;
              width: 18px;
              height: 17px;
            }
            &.active {
              color: #1c99b0;
            }
          }
          &:hover {
            padding: 25px 15px;
            background: #ffffff;
            box-shadow: 4px 2px 10px 0px rgba(0, 0, 0, 0.1);
          }
        }
      }
      .pull_more {
        margin-top: 23px;
        height: 40px;
        line-height: 40px;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
        text-align: center;
        background: #ebecec;
        cursor: pointer;
      }
    }
    .right {
      width: 330px;
      .section {
        margin-bottom: 20px;
        padding-bottom: 20px;
        background: #fff;
        &:not(:first-child) {
          padding: 25px 23px 0;
        }
        .color_title {
          padding: 8px 10px;
          display: flex;
          align-items: center;
          background: linear-gradient(90deg, #2f9b9d, #0f99be);
          .title_icon {
            width: 33px;
            height: 33px;
            border-radius: 50%;
          }
          .title {
            margin-left: 10px;
            font-size: 10px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #ffffff;
            .img {
              width: 42px;
              height: 14px;
            }
          }
          .right_text {
            margin-left: auto;
            font-size: 14px;
            font-family: PingFangSC;
            font-weight: 500;
            color: #ffffff;
          }
        }
        .section_content {
          margin: 20px 14px 0;
          padding: 15px 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #dedede;
          box-sizing: border-box;
          .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex: 1;
            height: 80px;
            font-size: 14px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #333333;
            cursor: pointer;
            &:first-child {
              border-right: 1px solid #dedede;
            }
            .img {
              margin-bottom: 15px;
              width: 36px;
              height: 30px;
            }
          }
        }
        .head {
          display: flex;
          justify-content: space-between;
          .title {
            padding-bottom: 6px;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #595757;
            border-bottom: 2px solid #4a97ad;
            .title_icon {
              margin-right: 10px;
              width: 22px;
              height: 20px;
            }
          }
          .title_more {
            padding-bottom: 6px;
            flex: 1;
            font-size: 10px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #a6a7a7;
            text-align: right;
            border-bottom: 1px solid #dedede;
          }
        }
        .list {
          margin: 5px -23px 0;
          .item {
            padding: 15px 23px;
            border-bottom: 1px solid #efefef;
            cursor: pointer;
            &:hover {
              background: #e9fcff;
            }
            &:last-child {
              border: none;
            }
            .title {
              font-size: 13px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #595757;
              line-height: 18px;
            }
            .subtitle {
              margin-top: 19px;
              display: flex;
              justify-content: space-between;
              font-size: 12px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #babbbb;
            }
          }
        }
      }
    }
  }
}
</style>